import React from "react";
import { Layout } from "../../layouts/Layout";
import { ServiceFive } from "../../components/Services/ServiceFive";
import { MainPorto } from "./MainPortoPage";

const ServicesPage = () => {
  return (
    <Layout breadcrumb={"Portofolio"} title={'Hasil Karya Kami'} >
      {/* services */}
      <MainPorto />
    </Layout>
  );
};

export default ServicesPage;
