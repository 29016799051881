import React from "react";
import { Link } from "react-router-dom";

export const CtaAreaFour = () => {
  return (
    <section className="cta-area-four">
      <div className="container">
        <div className="cta-inner-wrap-two">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div className="cta-content">
                <div className="cta-info-wrap">
                  <div className="icon">
                    <i className="flaticon-phone-call"></i>
                  </div>
                  <div className="content">
                    <span>Telfon/WhatsApp</span>
                    <a href="tel:6285348558783">+6285348558783</a>
                  </div>
                </div>
                <h2 className="title">
                  Butuh solusi digital? konsultasi gratis bersama kami sekarang!
                </h2>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="cta-btn text-end">
                <Link
                  to="https://wa.link/l2sgsq"
                  className="btn btn-three"
                  target="_blank"
                >
                  Kontak Kami
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
