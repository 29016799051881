import {
  H2_PROJECT_IMG01,
  H2_PROJECT_IMG02,
  H2_PROJECT_IMG03,
  H3_PROJECT_IMG02,
  H3_PROJECT_IMG03,
  H3_PROJECT_IMG04,
  H5_PROJECT_IMG01,
  H5_PROJECT_IMG02,
  H5_PROJECT_IMG03,
  H5_PROJECT_IMG04,
  H5_PROJECT_IMG05,
  PROJECT_IMG01,
  PROJECT_IMG02,
  PROJECT_IMG03,
} from "../lib/assets";

export const PROJECT_LIST_TWO = [
  {
    id: 1,
    img: H2_PROJECT_IMG01,
    href: "/project-details",
    title: "Finance Consultancy",
    tag: "Advisory",
  },
  {
    id: 2,
    img: H2_PROJECT_IMG02,
    href: "/project-details",
    title: "Finance Consultancy",
    tag: "Advisory",
  },
  {
    id: 3,
    img: H2_PROJECT_IMG03,
    href: "/project-details",
    title: "Finance Consultancy",
    tag: "Advisory",
  },
];

export const PROJECT_LIST_THREE = [
  {
    id: 1,
    href: "/project-details",
    src: H3_PROJECT_IMG02,
  },
  {
    id: 2,
    href: "/project-details",
    src: H3_PROJECT_IMG02,
  },
  {
    id: 3,
    href: "/project-details",
    src: H3_PROJECT_IMG03,
  },
  {
    id: 4,
    href: "/project-details",
    src: H3_PROJECT_IMG04,
  },
];

export const PROJECT_LIST_FOUR = [
  {
    id: 1,
    // href: "/project-details",
    href: "https://masjidenterprise.id",
    src: H5_PROJECT_IMG01,
    title: "Masjidenterprise.id",
    subtitle: "Education & Event Web App",
  },
  {
    id: 2,
    // href: "/project-details",
    href: "https://app.institutmasajidallah.com",
    src: H5_PROJECT_IMG02,
    title: "App.insitutmasajidallah.com",
    subtitle: "E-Learning Web App",
  },
  {
    id: 3,
    // href: "/project-details",
    href: "https://desawisatasungaikupah.com/",
    src: H5_PROJECT_IMG03,
    title: "Desa Wisata Sungai Kupah",
    subtitle: "Website",
  },
  {
    id: 4,
    // href: "/project-details",
    href: "/",
    src: H5_PROJECT_IMG04,
    title: "Aplikasi POS & Kasir",
    subtitle: "Website dan Aplikasi Android",
  },
  {
    id: 5,
    // href: "/project-details",
    href: "https://desawisatasungaikupah.com/",
    src: H5_PROJECT_IMG03,
    title: "Desa Wisata Sungai Kupah",
    subtitle: "Website",
  },
];

export const PROJECT_LIST_ONE = [
  {
    id: 1,
    href: "/project-details",
    tag: "Achievment",
    tagHref: "/project",
    src: PROJECT_IMG01,
    title: "Based on your Correct situation",
  },
  {
    id: 2,
    href: "/project-details",
    tag: "Business Strategy",
    tagHref: "/project",
    src: PROJECT_IMG02,
    title: "Best mortgage rates guaranteed.",
  },
  {
    id: 3,
    href: "/project-details",
    tag: "Investment",
    tagHref: "/project",
    src: PROJECT_IMG03,
    title: "Investment Policy For New Project",
  },
  {
    id: 4,
    href: "/project-details",
    tag: "Achievment",
    tagHref: "/project",
    src: PROJECT_IMG01,
    title: "Based on your Correct situation",
  },
];
